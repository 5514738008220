import React from "react";

import {
  FourOFourPage,
  GTMService,
  SEOMeta,
} from "@interactive-investor/onestack-web-shared";

import Layout from "../components/layout";

class NotFoundPage extends React.Component {
  componentDidMount() {
    GTMService.pushPageView("404 Not Found", window.location.pathname, "404");
  }

  render() {
    return (
      <>
        <SEOMeta
          meta={{
            title: "404 Not Found",
            robots: "noindex, nofollow",
            description: "",
            canonical_url: "",
          }}
          titleSuffix=""
        />
        <Layout>
          <FourOFourPage />
        </Layout>
      </>
    );
  }
}

export default NotFoundPage;
